import home from './home'
import pageMerchant from './merchant'
import pageFinance from './finance'
import pageReviewed from './reviewed'
import Rbac from "@/router/pages/admin/rbac";
import aftersales from "./aftersales"
import platform from "./platform"
import trading from './trading'
import closeapprove from './closeapprove'
import pageBusinessmag from './businessmag'
import pageCapital from './capital'
import PageRecharge from './recharge'
import marketing from "./marketing"
import channel from "./channel"
import storageAudit from "./storage_audit";
import brokerageAudit from "./brokerage_audit";
import userInfo from "./user_info"
import ad from "./ad"
import contract from "./contract"
import message from "./message"
import suggestion from "./suggestion"
import agent from "./agent"
import system from "./system"
import pageAgentMessage from './agentMessage'
import pageMessageCenter from './messageCenter'
import myServiceOrder from './my_service_order'
import riskMerchantHandle from './riskMerchantHandle' //风险商户审批
import merchantInvoice from "./merchantInvoice"


const admin = {
  path: '/',
  component: resolve => require(['@/pages/admin'], resolve),
  redirect: '/home',
  children: [
    home,
    pageMerchant,
    pageFinance,
    pageReviewed,
    Rbac,
    aftersales,
    platform,
    trading,
    closeapprove,
    pageBusinessmag,
    pageCapital,
    PageRecharge,
    marketing,
    channel,
    storageAudit,
    brokerageAudit,
    userInfo,
    ad,
    contract,
    message,
    suggestion,
    agent,
	pageAgentMessage,
    system,
    pageMessageCenter,
    myServiceOrder,
    riskMerchantHandle,
    merchantInvoice
  ],
  meta: {
    requireAuth: true
  }
}

export default admin